import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DreemHeader from './DreemHeader'

import Image from '../Image'
import { theme } from '../../../tailwind.config'
import DreemBulletItem from './DreemBulletItem'
import SensorsSvg from '../../img/inline/sensors.svg'
import AudioSvg from '../../img/inline/audio.svg'
import AccelerometerSvg from '../../img/inline/accelerometer.svg'
import MiscellaneousSvg from '../../img/inline/miscellaneous.svg'
import BatterySvg from '../../img/inline/battery.svg'
import StorageSvg from '../../img/inline/storage.svg'
import OutputSvg from '../../img/inline/output.svg'

const IconHeader = ({ children }) => {
    return (
        <div
            className="text-purple-700 text-18 font-semibold flex items-center"
            style={{ gap: '16px' }}
        >
            {children}
        </div>
    )
}

IconHeader.propTypes = {
    children: PropTypes.object,
}

export default function DreemPSGQuality({ psgImage, headbandImage }) {
    const [isSensorView, setIsSensorView] = useState(true)

    const highlightedStyles = {
        borderBottom: `3px solid ${theme.extend.colors.teal[500]}`,
        fontWeight: 700,
        cursor: 'pointer',
    }

    const nonHighlightedStyles = {
        borderBottom: 'none',
        fontWeight: 500,
        cursor: 'pointer',
    }

    return (
        <section className="w-full px-30 py-30 min-h-screen md:h-650 flex flex-col justify-between">
            <DreemHeader
                title="PSG quality"
                description="In a lightweight, easy to use device"
            />
            <div className="mt-100 mx-auto max-w-1264 w-full flex flex-col md:flex-row justify-around sm:text-12">
                <div className="w-full md:w-1/3">
                    <div className="mt-0 md:mt-100">
                        <IconHeader>
                            <SensorsSvg />
                            {isSensorView ? 'EEG sensors' : 'The Headband'}
                        </IconHeader>
                    </div>
                    <div className="text-pewter-700 text-16 font-semibold mt-32">
                        {isSensorView && '5 EEG sensors to measure EEG:'}
                    </div>
                    {isSensorView ? (
                        <>
                            <div className="flex mt-24">
                                <div
                                    className="text-teal-600 text-16 font-semibold"
                                    style={{
                                        marginRight: '6px',
                                        color: '#1FDCB3',
                                    }}
                                >
                                    A.
                                </div>
                                <div className="text-16 text-pewter-700">
                                    2 frontal sensors in F7 and F8 locations to
                                    measure frontal brain activity
                                </div>
                            </div>
                            <div className="flex mt-24">
                                <div
                                    className="text-teal-600 text-16 font-semibold"
                                    style={{
                                        marginRight: '6px',
                                        color: '#1FDCB3',
                                    }}
                                >
                                    B.
                                </div>
                                <div className="text-16 text-pewter-700">
                                    1 ground sensor on the frontal band (Fp2
                                    location)
                                </div>
                            </div>
                            <div className="flex mt-24">
                                <div
                                    className="text-teal-600 text-16 font-semibold"
                                    style={{
                                        marginRight: '6px',
                                        color: '#1FDCB3',
                                    }}
                                >
                                    C.
                                </div>
                                <div className="text-16 text-pewter-700 mb-32">
                                    2 occipital sensors on O1 and O2 locations
                                    to monitor occipital brain activity
                                </div>
                            </div>
                        </>
                    ) : (
                        <div>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    paddingRight: '30px',
                                    paddingLeft: '30px',
                                }}
                            >
                                <DreemBulletItem text="Head circumference:520-620mm" />
                                <DreemBulletItem text="Material: Fabric" />
                            </ul>
                            <div className="mt-32 mb-32">
                                <IconHeader>
                                    <BatterySvg />
                                    Battery
                                </IconHeader>
                            </div>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    paddingRight: '30px',
                                    paddingLeft: '30px',
                                }}
                            >
                                <DreemBulletItem text="Maximum charging time: 3h30" />
                                <DreemBulletItem text="Average charging time: 1h for up to 8h of recording" />
                            </ul>
                        </div>
                    )}
                </div>
                <div>
                    <Image
                        image={isSensorView ? psgImage : headbandImage}
                        className="h-auto"
                    />
                </div>

                <div className="w-full md:w-1/3">
                    {isSensorView ? (
                        <div>
                            <IconHeader>
                                <AudioSvg />
                                Audio
                            </IconHeader>
                            <div className="flex mt-24">
                                <div
                                    className="text-teal-600 text-16 font-semibold"
                                    style={{
                                        marginRight: '6px',
                                        color: '#1FDCB3',
                                    }}
                                >
                                    D.
                                </div>
                                <div className="text-16 text-pewter-700">
                                    Bone conduction speaker for audio output
                                </div>
                            </div>
                            <div className="mt-32">
                                <IconHeader>
                                    <AccelerometerSvg />
                                    Accelerometer
                                </IconHeader>
                            </div>
                            <div className="flex mt-24">
                                <div
                                    className="text-teal-600 text-16 font-semibold"
                                    style={{
                                        marginRight: '6px',
                                        color: '#1FDCB3',
                                    }}
                                >
                                    E.
                                </div>
                                <div className="text-16 text-pewter-700">
                                    3D accelerometer to measure movements, head
                                    position, and respiratory rate/trace during
                                    sleep
                                </div>
                            </div>
                            <div className="mt-32">
                                <IconHeader>
                                    <MiscellaneousSvg />
                                    Miscellaneous
                                </IconHeader>
                            </div>
                            <div className="flex mt-24">
                                <div
                                    className="text-teal-600 text-16 font-semibold"
                                    style={{
                                        marginRight: '6px',
                                        color: '#1FDCB3',
                                    }}
                                >
                                    F.
                                </div>
                                <div className="text-16 text-pewter-700">
                                    Power Button
                                </div>
                            </div>
                            <div className="flex mt-24">
                                <div
                                    className="text-teal-600 text-16 font-semibold"
                                    style={{
                                        marginRight: '6px',
                                        color: '#1FDCB3',
                                    }}
                                >
                                    G.
                                </div>
                                <div className="text-16 text-pewter-700">
                                    Magnet port for charging
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="mb-32">
                                <IconHeader>
                                    <StorageSvg />
                                    Storage
                                </IconHeader>
                            </div>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    paddingRight: '30px',
                                    paddingLeft: '30px',
                                }}
                            >
                                <DreemBulletItem text="Maximum number of recordings on memory: Up to 25" />
                                <DreemBulletItem text="Maximum record duration: Up to 25h" />
                            </ul>
                            <div className="mb-32 mt-32">
                                <IconHeader>
                                    <OutputSvg />
                                    Output
                                </IconHeader>
                            </div>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    paddingRight: '30px',
                                    paddingLeft: '30px',
                                }}
                            >
                                <DreemBulletItem text="EEG Channels: 5" />
                                <DreemBulletItem text="Sleep endpoints: TST, WASO, N1, N2, N3, REM sleep, SOL, nb and duration of awakenings" />
                                <DreemBulletItem text="Raw data: H5 and EDF" />
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div
                className="text-pewter-700 text-28 max-w-1264 w-full mx-auto"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '32px',
                    paddingRight: '32px',
                    paddingLeft: '32px',
                }}
            >
                <div
                    style={
                        isSensorView ? highlightedStyles : nonHighlightedStyles
                    }
                    onClick={() => {
                        setIsSensorView(true)
                    }}
                >
                    Sensors
                </div>
                <div
                    onClick={() => {
                        setIsSensorView(false)
                    }}
                    style={
                        !isSensorView ? highlightedStyles : nonHighlightedStyles
                    }
                >
                    Specs
                </div>
            </div>
        </section>
    )
}

DreemPSGQuality.propTypes = {
    psgImage: PropTypes.shape({
        extension: PropTypes.string,
        publicURL: PropTypes.string,
    }),
    headbandImage: PropTypes.shape({
        extension: PropTypes.string,
        publicURL: PropTypes.string,
    }),
}
